import * as React from "react";
import { useEffect } from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";

export default function Home({ location }) {
  useEffect(() => {
    import("@nielsen-media/gds-web");
    // import("@nielsen-media/gds-utilities");
    // import("@nielsen-media/gds-web/gds-button/lib/src");             GOOD
    // import("@nielsen-media/gds-web/gds-action-icon/lib/src");        GOOD
    // import("@nielsen-media/gds-web/gds-card/lib/src");
    // import("@nielsen-media/gds-web/gds-chip/lib/src");
    // import("@nielsen-media/gds-web/gds-checkbox/lib/src");           GOOD
    // import("@nielsen-media/gds-web/gds-icons/lib/src");
    // import("@nielsen-media/gds-web/gds-input/lib/src");
    // import("@nielsen-media/gds-web/gds-label/lib/src");
    // import("@nielsen-media/gds-web/gds-link/lib/src");               GOOD
    // import("@nielsen-media/gds-web/gds-list-item/lib/src");
    // import("@nielsen-media/gds-web/gds-list-group/lib/src");
    // import("@nielsen-media/gds-web/gds-logo/lib/src");
    // import("@nielsen-media/gds-web/gds-radio/lib/src");
    // import("@nielsen-media/gds-web/gds-radio-group/lib/src");
    // import("@nielsen-media/gds-web/gds-select/lib/src");
    // import("@nielsen-media/gds-web/gds-single-select/lib/src");
    // import("@nielsen-media/gds-web/gds-switch/lib/src");
    // import("@nielsen-media/gds-web/gds-styles");
  }, []);

  return (
    <Layout location={location}>
      <div className="background-style"></div>
      <div
        className="styled-grid gap-space-800"
        style={{ height: "100%", alignContent: "center" }}
      >
        <div
          className="styled-grid gap-space-400 mt-space-500"
          style={{ maxWidth: "800px" }}
        >
          <div
            className="gds-size-1000-bold"
            style={{ color: "white" }}
            id="site-title"
          >
            Nielsen Global Design System
          </div>
          <div
            className="gds-size-800-regular"
            style={{ color: "white" }}
            id="site-description"
          >
            GDS is a library of components and design specifications to help
            teams build elegant & cohesive experiences for Nielsen products.
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-6 p-space-300">
            <div className="card-tertiary">
              <div className="flex-card">
                <div className="gds-size-800-bold" style={{ color: "white" }}>
                  Foundations
                </div>
                <div
                  className="gds-size-300-regular"
                  style={{ color: "white" }}
                >
                  These design princples are the base and the scaffolding for
                  building our products.
                </div>
                <Link to="/foundations" activeClassName="active">
                  <gds-button size="compact">Learn More</gds-button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 p-space-300">
            <div className="card-tertiary">
              <div className="flex-card">
                <div className="gds-size-800-bold" style={{ color: "white" }}>
                  Components
                </div>
                <div
                  className="gds-size-300-regular"
                  style={{ color: "white" }}
                >
                  These reusable parts are help you quickly snap your designs
                  together.
                </div>
                <Link to="/components" activeClassName="active">
                  <gds-button size="compact" width="100%">
                    Learn More
                  </gds-button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-space-300">
            <div className="card-tertiary">
              <div className="flex-card">
                <div className="gds-size-800-bold" style={{ color: "white" }}>
                  Nielsen Brand
                </div>
                <div
                  className="gds-size-300-regular"
                  style={{ color: "white" }}
                >
                  Our brand guidelines provide the visual palette and voice for
                  all Nielsen products.
                </div>
                <a
                  target="_new"
                  href="https://www.nielsen.com/us/en/press-releases/2021/nielsen-unveils-new-brand-identity-reflecting-companys-transformation-and-focus-on-the-global-future-of-media/"
                  activeClassName="active"
                >
                  <gds-button size="compact">
                    View Brand Announcement
                  </gds-button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const Head = () => (
  <>
    <title>Nielsen Global Design System</title>
    <meta
      name="description"
      content="Content and documentation relating to Nielsen's Global Design System."
    />
    <meta name="image" content="/nielsen-gds-share.png" />
  </>
);
